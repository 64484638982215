/* eslint-disable no-undef */
// Cypress
export const isCypressActive = !!window.Cypress;

// Origin
export const isProduction =
  location.hostname === "www.telegraphbay.app";
export const isDev = location.origin.includes(":8081");

// Bucket
export const staticBucketUrl = process.env.STATIC_BUCKET_URL || "https://statichk.footprint.network";
